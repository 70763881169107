//FacilityModal.vue

<template>
	<app-modal :modal="facilityModal" @closeModal="closeModal">
		<template slot="title">Facility</template>

		<form
			v-on:submit.prevent="saveHospital"
			ref="myForm"
			class="-needs-validation -was-validated"
			:class="{ 'was-validated': errors.items.length > 0 && formSubmitted }"
		>
			<pre>{{ errors.items.length }}</pre>

			<section class="form-row">
				<div class="col-6">
					<div class="form-group">
						<label>
							Facility Name
							<code>{{ hospital.id }}</code>
						</label>

						<input
							v-model="form.name"
							type="text"
							id="facility_name"
							name="Facility Name"
							placeholder="Facility Name"
							class="form-control form-control-lg -is-invalid"
							v-validate="'required'"
							required
						/>

						<!-- <div class="invalid-feedback" v-show="errors.has('Facility Name')">{{ errors.first('Facility Name') }}</div> -->
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label>
							Facility Type
						</label>

						<select
							class="custom-select form-control-lg"
							id="facility_type"
							name="facility_type"
							v-model="form.facility_type"
							v-validate="'required'"
							required
						>
							<option value>- Select -</option>
							<option
								v-for="(key, value) in options.facility_types"
								:value="value"
								:key="value"
							>
								{{ key }}
							</option>
						</select>
					</div>
				</div>

				<div class="col-6 mb-2">
					<div class="form-group">
						<label for="mobile">Twilio Phone Number to Call Before Starting Video Call</label>
						<div class="input-group input-group-lg" ref="mobile">
							<div class="input-group-prepend">
								<span class="input-group-text" id="inputGroupPrepend2">#</span>
							</div>

							<input
									type="number"
									class="form-control"
									name="mobile"
									id="mobile"
									placeholder="Enter a phone number"
									v-model="form.twilio_phone_number_to_call_before_video_call"
							/>
						</div>
					</div>
				</div>

			</section>

			<div class="input-group mb-3">
				<div class="input-group-prepend">
					<span
						class="input-group-text"
						style="font-size: 14px;"
						id="basic-addon1"
						>Twilio Call Route (DO NOT CHANGE)</span
					>
				</div>
				<input
					disabled
					v-model="form.twilio_call_route"
					type="text"
					id="twilio_call_route"
					name="twilio_call_route"
					placeholder="Enter URL for Twilio Call Route"
					class="form-control form-control-sm -is-invalid"
				/>
			</div>

			<section class="form-row">
				<div class="mb-3 d-flex align-items-top col-md ">
					<div class="custom-control custom-checkbox my-1 mr-sm-2">
						<input
							v-model="form.use_unique_caller_id"
							type="checkbox"
							class="custom-control-input"
							id="use_unique_caller_id"
						/>
						<label class="custom-control-label" for="use_unique_caller_id"
							>Use unique Twilio phone number for Caller ID</label
						>
					</div>
				</div>

				<div class="mb-3 d-flex align-items-top col-md">
					<div class="custom-control custom-checkbox my-1 mr-sm-2">
						<input
							v-model="form.ignore_state_license_when_routing"
							type="checkbox"
							class="custom-control-input"
							id="ignore_state_license_when_routing"
						/>
						<label
							class="custom-control-label"
							for="ignore_state_license_when_routing"
							>Route calls to first available RT regardless of state license.
						</label>
					</div>
				</div>
			</section>

			<section class="form-row">
				<div class="mb-3 d-flex align-items-top col-md">
					<div class="custom-control custom-checkbox my-1 mr-sm-2">
						<input
							v-model="form.require_call_acceptance"
							type="checkbox"
							class="custom-control-input"
							id="require_call_acceptance"
						/>
						<label class="custom-control-label" for="require_call_acceptance"
							>Require Call Acceptance (iPad Facility).
							<span class="text-muted"
								>RT will hear "Press X to accept phone call"</span
							>
						</label>
					</div>
				</div>

				<div class="mb-3 d-flex align-items-top col-md">
					<div class="custom-control custom-checkbox my-1 mr-sm-2">
						<input
							v-model="form.send_to_voicemail"
							type="checkbox"
							class="custom-control-input"
							id="send_to_voicemail"
							:disabled="send_to_voicemail_disable"
							style="transition-duration: .5s;"
						/>
						<label
							class="custom-control-label"
							for="send_to_voicemail"
							style="transition-duration: .5s;"
							>Send to voicemail if no one answers the phone.
						</label>
					</div>
				</div>
			</section>

			<section class="form-row">
				<div class="mb-3 d-flex align-items-top col-md">
					<div class="custom-control custom-checkbox my-1 mr-sm-2">
						<input
							v-model="form.hunt_group_call_routing"
							type="checkbox"
							class="custom-control-input"
							id="hunt_group_call_routing"
						/>
						<label class="custom-control-label" for="hunt_group_call_routing"
							>Hunt-group Call Routing (with Call Acceptance - no iPad)
						</label>
					</div>
				</div>
			</section>

			<div class="form-group">
				<label>EMR</label>

				<input
					v-model="form.emr_link"
					type="text"
					id="emr"
					name="Facility Name"
					placeholder="Enter URL for EMR"
					class="form-control form-control-lg -is-invalid"
				/>

				<!-- <div class="invalid-feedback" v-show="errors.has('Facility Name')">{{ errors.first('Facility Name') }}</div> -->
			</div>

			<div class="form-group">
				<label>Contracted Hours</label>

				<table class="table table-sm">
					<template v-for="(day, n) in form.contractedTimes">
						<tr
							:key="n + '-facility-model-contracted-times-day'"
							v-if="!day.times.length"
						>
							<th style="border-top: 2px solid #6c757d">{{ day.day }}</th>
							<td colspan="4"></td>
							<td style="text-align: right">
								<a @click.prevent="addContractedTime(n)" href class="btn icon-only">
									<font-awesome-icon style="font-size: x-large" icon="fa-solid fa-circle-plus" />
								</a>
							</td>
						</tr>

						<tr
							v-for="(time, i) in day.times"
							:key="n + '-' + i + '-facility-model-contracted-times-time'"
						>
							<th v-if="i === 0" style="border-top: 2px solid #6c757d">
								<span>{{ day.day }}</span>
							</th>
							<th v-else></th>
							<td>
								<app-time-picker :time="time.start"></app-time-picker>
							</td>
							<td align="center" style="text-align: center">to</td>
							<td>
								<div v-show="!time.eod">
									<app-time-picker :time="time.end"></app-time-picker>
								</div>
							</td>
							<td>
								<a
									title="End of day?"
									@click.prevent="toggleEOD(time)"
									class="btn btn-secondary text-white"
								>
									<i v-if="time.eod" class="material-icons -lime-text">check</i>
									<span v-if="!time.eod">EOD?</span>
									<span v-else>12:00 AM end-of-day</span>
								</a>
							</td>
							<td style="text-align: right">
								<a
									href
									@click.prevent="removeContractedTime(n, i)"
									class="btn red-text icon-only"
								>
									<font-awesome-icon style="font-size: x-large" icon="fa-solid fa-circle-minus" />
								</a>
								<a
									@click.prevent="addContractedTime(n)"
									v-if="day.times.length == i + 1"
									href
									class="btn icon-only"
								>
									<font-awesome-icon style="font-size: x-large" icon="fa-solid fa-circle-plus" />
								</a>
							</td>
						</tr>
					</template>
				</table>
			</div>

			<div class="form-group">
				<label>Location</label>

				<div class="input-group mb-2">
					<input
						type="text"
						placeholder="Address"
						name="Address"
						v-model="form.address_1"
						class="form-control form-control-lg"
						v-validate="'required'"
						required
					/>
				</div>

				<div class="input-group mb-2">
					<input
						type="text"
						placeholder="City"
						name="City"
						v-model="form.city"
						class="form-control form-control-lg"
						v-validate="'required'"
						required
					/>

					<select
						class="custom-select form-control-lg"
						id="facility.state"
						name="State"
						v-model="form.state"
						v-validate="'required'"
						required
					>
						<option value>- State -</option>
						<option
							v-for="(key, value) in options.states"
							:value="value"
							:key="value"
						>
							{{ key }}
						</option>
					</select>

					<input
						type="number"
						placeholder="Zipcode"
						name="Zipcode"
						v-model="form.zip"
						class="form-control form-control-lg"
						v-validate="'required|min:5'"
						required
					/>
				</div>

				<div
					style="display: block"
					class="invalid-feedback"
					v-show="errors.has('Timezone')"
				>
					A facility timezone is required.
				</div>
			</div>

			<div class="form-group">
				<div class="form-row">
					<label class="col-6">Email</label>
					<label class="col-6">Timezone</label>
				</div>

				<div class="input-group">
					<input
						type="email"
						placeholder="Facility Email"
						v-model="form.email"
						class="form-control form-control-lg"
					/>

					<select
						class="custom-select form-control-lg"
						id="facility.timezone"
						name="Timezone"
						v-model="form.timezone"
						v-validate="'required'"
						required
					>
						<option value>- Timezone -</option>
						<option
							v-for="(key, value) in options.timezones"
							:value="value"
							:key="value"
						>
							{{ value }}
						</option>
					</select>
				</div>

				<div class="form-row mt-3">
					<label class="col-6">Phone</label>
					<label class="col-6">Fax</label>
				</div>

				<div class="input-group">
					<input
						type="text"
						placeholder="Phone #"
						v-model="form.phone"
						class="form-control form-control-lg"
					/>

					<input
						type="text"
						name="Fax"
						placeholder="Fax #"
						v-model="form.fax"
						class="form-control form-control-lg"
						v-validate="'required|min:5'"
						required
					/>

					<div class="invalid-feedback" v-show="errors.has('Fax')">
						{{ errors.first("Fax") }}
					</div>
				</div>

				<div class="form-group">
					<div class="form-row mt-4">
						<section class="col-auto mb-2" v-if="form.user_limit">
							<div class="btn-group-toggle" data-toggle="buttons">
								<label
									class="btn"
									:class="{
										'text-light blue-grey': form.user_limit.active,
										'btn-outline-secondary': !form.user_limit.active
									}"
									><input
										v-model="form.user_limit.active"
										type="checkbox"
										id="limitUserAccounts"
										value="1"
									/>

									<i
										v-if="form.user_limit.active"
										class="material-icons -lime-text"
										>check</i
									>
									<i v-else class="material-icons plus">close</i>

									Limit Users
								</label>
								<span class="text-muted pl-2" v-if="form.user_limit.active">
									{{ form.users_count }} current user{{
										form.users_count > 1 ? "s" : ""
									}}</span
								>
							</div>

							<fieldset class="mt-1 mr-4 mb-4" v-show="form.user_limit.active">
								<!--								<label></label>-->
								<input
									type="text"
									name="corelink_contracted_minutes"
									placeholder="Corelink Contracted Minutes"
									v-model="form.user_limit.count"
									class="form-control form-control-lg"
									required
								/>
								<small class="form-text text-muted">
									Limit number of accounts that can be created for this
									facility.
								</small>
							</fieldset>
						</section>

						<section class="col-auto" v-if="form.corelink_feature">
							<div class="btn-group-toggle" data-toggle="buttons">
								<label
									class="btn"
									:class="{
										'text-light blue-grey': form.corelink_feature.active,
										'btn-outline-secondary': !form.corelink_feature.active
									}"
									><input
										v-model="form.corelink_feature.active"
										type="checkbox"
										id="filterUsers[]"
										value="1"
									/>

									<i
										v-if="form.corelink_feature.active"
										class="material-icons -lime-text"
										>check</i
									>
									<i v-else class="material-icons plus">close</i>

									CoreLink Features
								</label>
							</div>

							<fieldset class="mt-1 mr-4" v-show="form.corelink_feature.active">
								<!--								<label></label>-->
								<input
									type="text"
									name="corelink_contracted_minutes"
									placeholder="Corelink Contracted Minutes"
									v-model="form.corelink_feature.contracted_minutes"
									class="form-control form-control-lg"
									required
								/>
								<small class="form-text text-muted">
									Corelink Contracted Minutes
								</small>
							</fieldset>
						</section>

						<section class="col-auto" v-if="form.rtnow_feature">
							<div class="btn-group-toggle" data-toggle="buttons">
								<label
									for="rtnow-feature-active"
									class="btn"
									:class="{
										'text-light blue-grey': form.rtnow_feature.active,
										'btn-outline-secondary': !form.rtnow_feature.active
									}"
									><input
										v-model="form.rtnow_feature.active"
										type="checkbox"
										id="rtnow-feature-active"
										value="1"
									/>

									<i
										v-if="form.rtnow_feature.active"
										class="material-icons -lime-text"
										>check</i
									>
									<i v-else class="material-icons plus">close</i>

									rtNOW Features
								</label>
							</div>

							<fieldset class="mt-1" v-show="form.rtnow_feature.active">
								<!--								<label>rtNOW Contracted Minutes</label>-->
								<input
									type="text"
									name="rtnow_contracted_minutes"
									placeholder="rtNOW Contracted Minutes"
									v-model="form.rtnow_feature.contracted_minutes"
									class="form-control form-control-lg"
									required
								/>
								<small class="form-text text-muted">
									rtNOW Contracted Minutes
								</small>
							</fieldset>
						</section>
					</div>
				</div>
			</div>
		</form>

		<template slot="footer">
			<button
				type="button"
				class="btn btn-lg btn-secondary"
				data-dismiss="modal"
				v-on:click.prevent="toggleFacilityModal(false)"
			>
				Close
			</button>
			<button
				type="button"
				class="btn btn-lg btn-primary"
				v-on:click.prevent="triggerSubmit"
			>
				Save
			</button>
		</template>
	</app-modal>
</template>

<script>
import { mapActions } from "vuex";

//import VueTimepicker from 'vue2-timepicker';
//import FacilityModal from './FacilityModal.vue'
import TimePicker from "./TimePicker.vue";
//import FacilityAlerts from './FacilityAlerts.vue'

export default {
	props: ["showFacilityModal", "hospital"],

	data() {
		return {
			options: {
				states: []
			},
			form: {
				emr: "",
				city: "",
				state: "",
				weeklyUtilization: [],
				contractedTimes: [],
				ignore_state_license_when_routing: false,
				send_to_voicemail: false,
				hunt_group_call_routing: false,
				require_call_acceptance: false,
				twilio_phone_number_to_call_before_video_call: "",
				//facilityAlerts: []
			},
			send_to_voicemail_disable: false,
			utilized: "never",
			formSubmitted: false
		};
	},

	methods: {
		...mapActions(["ajax", "toggleFacilityModal", "globalAlert"]),

		toggleEOD(time) {
			//each time slot can extend to the end of the day or midnight
			time.eod = !time.eod;

			if (time.eod) {
				// if going to end of display
				// set the end time to midnight
				time.end = {
					h: 12,
					m: "00",
					a: "AM"
				};
			}
		},

		// deleteAlert(n){
		// 	//this.form.facilityAlerts;
		//
		// 	this.$delete(this.form.facilityAlerts, n);
		// },

		// addAlert(){
		//
		// 	var date = new Date();
		// 	var timestamp = date.getTime();
		//
		// 	this.form.facilityAlerts.push({
		// 		text: 'Attention',
		// 		style: 'primary',
		// 		key: timestamp
		// 	});
		// },

		removeContractedTime(day, time) {
			this.form.contractedTimes[day].times.splice(time, 1);
		},
		addContractedTime(day) {
			this.form.contractedTimes[day].times.push({
				start: {
					h: null,
					m: "00",
					a: null
				},
				end: {
					h: null,
					m: "00",
					a: null
				},
				eod: false // end of day
			});
		},

		// determineUtilization(){
		//
		// 	var utilized = 'never';
		//
		// 	var utilizedTrue = 0;
		// 	var utilizedFalse = 0;
		//
		// 	this.form.weeklyUtilization.forEach((d)=> {
		// 		console.log('day');
		// 		console.log(d.day);
		//
		// 		d.times.forEach((tim)=> {
		// 			//console.log(tim.time);
		//
		// 			if(tim.active == true){
		// 				utilizedTrue++;
		// 			}
		// 			else {
		// 				utilizedFalse++;
		// 			}
		// 		});
		// 	});
		//
		// 	console.log('utilizedTrue', utilizedTrue);
		// 	console.log('utilizedFalse', utilizedFalse);
		//
		//
		// 	if(utilizedTrue && !utilizedFalse){
		// 		this.utilized = 'always';
		// 	}
		// 	else if(utilizedTrue && utilizedFalse){
		// 		this.utilized = 'always';
		// 	}
		// 	else if(!utilizedTrue && utilizedFalse){
		// 		this.utilized = 'never';
		// 	}
		//
		//
		//
		// },

		// activeTab(b){
		//
		// 	this.form.weeklyUtilization.forEach((item) => {
		// 		this.$set(item, 'active', false);
		// 	});
		//
		// 	this.$set(b,'active',true);
		// },

		// this is emmitted from the child Modal Component
		closeModal() {
			this.hospital.id = "";
			this.toggleFacilityModal(false);
		},

		triggerSubmit() {
			//this.$refs.myForm.dispatchEvent(new Event("submit"));

			this.saveHospital();
		},

		saveHospital() {
			//alert('saveHospital');

			this.formSubmitted = true;

			this.$validator.validateAll().then(result => {
				if (result) {
					// eslint-disable-next-line
					//alert('Form Submitted!');

					//var tis = this;

					var id = this.hospital.id ? this.hospital.id : "";

					this.ajax({
						invalid: true, // show server-side validation errors
						url: "/facility/save/" + id,
						data: this.form,
						success: json => {
							if (json.validation_errors) {
								//return false;
							} else if (json.error) {
								//return false;
							} else {
								this.$emit("refreshHospitals");

								this.closeModal();
							}
						}
					});

					return;
				} // if

				this.globalAlert({
					title: "Form Errors",
					body: "Please correct errors."
				});
			}); // validateAll
		} // saveHospital
	},

	computed: {
		facilityModal() {
			return {
				show: this.$store.state.showFacilityModal,
				id: "facilityModal"
			};
		},

		hospitalId() {
			return this.hospital.id;
		}
		/*
		valid(){

			return {
				facility_name: {
					valid: (this.form.facility_name == '')? false : true,
				},
				city: {
					valid: (this.form.city == '')? false : true,
				}
			}

		}*/
	},

	watch: {
		"form.require_call_acceptance": {
			handler(newVal) {
				if (newVal) {
					setTimeout(() => {
						this.form.hunt_group_call_routing = false;
						this.form.send_to_voicemail = true;
					}, 50);
					setTimeout(() => {
						this.send_to_voicemail_disable = true;
					}, 150);
				} else {
					this.send_to_voicemail_disable = false;
				}
			}
		},

		"form.hunt_group_call_routing": {
			handler(newVal) {
				if (newVal) {
					setTimeout(() => {
						this.form.require_call_acceptance = false;
						this.form.send_to_voicemail = true;
					}, 60);
					setTimeout(() => {
						this.send_to_voicemail_disable = true;
					}, 150);
				} else {
					this.send_to_voicemail_disable = false;
				}
			}
		},

		utilized: function() {
			//console.log('watch utilized');

			//console.log(this.utilized);

			var active = true;

			if (this.utilized == "never") {
				active = false;
			}

			this.form.weeklyUtilization.forEach(d => {
				//console.log('day');
				//console.log(d.day);

				d.times.forEach(tim => {
					//console.log(tim.time);
					tim.active = active;
				});
			});
		},

		hospitalId: function() {
			if (this.hospital.id) {
				//alert('id changed');

				//var tis = this;

				this.ajax({
					url: "/facility/get/" + this.hospital.id,
					//data: {},
					success: json => {
						//this.form = json.facility;

						this.$set(this, "form", json.facility);
						this.$set(this, "options", json.options);
						//
						// this.$set(this.form, 'facilityAlerts', [
						// 	{
						// 		text: 'this is my alert text for this facility.',
						// 		style: 'warning',
						//
						// 	}
						// ]);

						// this.$set(this.form, 'contractedTimes', [
						// 	{
						// 		day: 'Sun',
						// 		times: [
						// 			{
						// 				start: {
						// 					h: 9,
						// 					m: 15,
						// 					a: 'AM'
						// 				},
						// 				end: {
						// 					h: 5,
						// 					m: 45,
						// 					a: 'PM'
						// 				}
						// 			},
						// 			{
						// 				start: {
						// 					h: 8,
						// 					m: 15,
						// 					a: 'AM'
						// 				},
						// 				end: {
						// 					h: 3,
						// 					m: 15,
						// 					a: 'PM'
						// 				}
						// 			},
						// 		]
						// 	},
						// 	{
						// 		day: 'Mon',
						// 		times: []
						// 	}
						// ]);

						//console.log('context', context);
						//this.determineUtilization();
					}
				});
			}

			// new init for facitly
			else if (this.facilityModal.show) {
				this.form = {};

				this.form.state = "";

				//var tis = this;

				this.ajax({
					url: "/facility/init/",
					//data: {},
					success: json => {
						//this.form = json.facility;
						this.$set(this, "form", json.facility);
						this.$set(this, "options", json.options);
					}
				});
			}
		}
	},

	mounted() {
		//var tis = this;
		// this.ajax({
		// 	url: "/facility/get",
		// 	data: {},
		// 	success: json => {
		// 		this.rtlist = json.rtlist;
		// 	}
		// });
	},

	components: {
		//'app-facility-alert': FacilityAlerts,
		"app-time-picker": TimePicker
	}
};
</script>

<style lang="scss"></style>

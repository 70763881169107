<template>
	<div class="container-fluid">
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>Facilities</h1>
				<p class="lead text-muted">
					Manage Respiratory Therapist system users for each hospital and other
					facilities.
				</p>
			</div>
			<div class="col-auto mr-auto">
				<a
					class="btn btn-lg btn-success"
					v-on:click.prevent="newHospital()"
					href
				>
					<i class="material-icons">&#xE145;</i>Add New
				</a>
			</div>
		</div>

		<pre>hospitals: {{ hospitals }}</pre>

		<div class="table-responsive">
			<table class="table -table-bordered">
				<thead>
					<tr>
						<th scope="col" style="text-align: center">#</th>
						<th scope="col">Name</th>
						<!--						<th scope="col">Address</th>-->
						<th scope="col">Fax</th>
						<th scope="col">Timezone</th>
						<th scope="col">Users</th>
						<th scope="col">Features</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="n in hospitals" :key="n.id">
						<td style="text-align: center">{{ n.id }}</td>
						<td>
							<strong>{{ n.name }}</strong> <br />{{ n.city }}, {{ n.state }}
						</td>
						<td>{{ n.fax }}</td>
						<td>{{ n.timezone }}</td>

						<td class="text-muted">
							<span
								:class="{
									'text-danger':
										n.user_limit.active && n.users_count >= n.user_limit.count
								}"
							>
								{{
									n.user_limit.active
										? n.users_count + " of " + n.user_limit.count
										: "Unlimited"
								}}
							</span>
						</td>

						<td>
							<!--							n.rtnow_feature: {{ n.rtnow_feature }}-->
							<!--							{{ n.corelink_feature }}-->

							<span
								v-if="n.rtnow_feature && n.rtnow_feature.active"
								class="badge badge-pill text-light mr-1 blue"
								>rtNOW
								{{
									n.rtnow_feature.contracted_minutes > 0
										? n.rtnow_feature.contracted_minutes + " minutes"
										: "unlimited"
								}}
							</span>
							<span
								v-if="n.corelink_feature && n.corelink_feature.active"
								class="badge badge-pill text-light mr-1 blue-grey"
								>CoreLink
								{{
									n.corelink_feature.contracted_minutes > 0
										? n.corelink_feature.contracted_minutes + " minutes"
										: "unlimited"
								}}
							</span>
						</td>

						<td align="right" class="text-right">
							<a
								href
								class="btn btn-outline-danger"
								v-on:click.prevent="removeHospital(n.id, false)"
								>Remove</a
							>
							<a
								href
								style="margin-left: .25em"
								class="btn btn-outline-primary"
								v-on:click.prevent="editHospital(n.id)"
								>Edit</a
							>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<app-hospital-modal
			@refreshHospitals="refreshHospitals"
			:showFacilityModal="showFacilityModal"
			:hospital="selectedHospital"
		></app-hospital-modal>

		<app-modal :modal="removeFacilityModal" data-v-on-close-modal="closeModal">
			<template slot="title">Confirm Removal Hospital</template>

			<form>
				<p>Are you sure you want to permanently remove this hospital?</p>
			</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-outline-secondary"
					data-dismiss="modal"
					v-on:click.prevent="removeFacilityModal.show = false"
				>
					Never mind
				</button>
				<button
					type="button"
					class="btn btn-danger"
					v-on:click.prevent="removeHospital(removeFacilityModal.id, true)"
					style="margin-right: .25em"
				>
					Remove
				</button>
			</template>
		</app-modal>
	</div>
</template>

<script>
import FacilityModal from "./FacilityModal.vue";

import { mapActions } from "vuex";

export default {
	data() {
		return {
			hospitals: [],
			options: {
				states: []
			},
			selectedHospital: {
				id: 568
				//utilized: 'always'
			},
			showFacilityModal: false,

			removeFacilityModal: {
				show: false,
				id: null // the id of the hospital to remove
			}
		};
	},

	methods: {
		...mapActions(["ajax", "toggleFacilityModal"]),

		newHospital() {
			this.$set(this.selectedHospital, "id", null);

			this.toggleFacilityModal(true);
		},

		editHospital(id) {
			this.$set(this.selectedHospital, "id", id);

			this.toggleFacilityModal(true);
		},

		removeHospital(id, confirmed) {
			if (!confirmed) {
				this.removeFacilityModal.show = true;
				this.removeFacilityModal.id = id;
			} else {
				this.removeFacilityModal.id = null;

				//var tis = this;

				this.ajax({
					url: "/facility/delete/" + id,
					data: {},
					success: () => {
						// find the object in this array where event id matches
						var selectedHos = this.hospitals.find(function(ele) {
							return ele.id == id;
						});

						// find the array index of this object
						var i = this.hospitals.indexOf(selectedHos);

						// delete the array child at a given index
						this.$delete(this.hospitals, i);

						this.removeFacilityModal.show = false;
					}
				});
			}
		},

		refreshHospitals() {
			//alert("refreshHospitals");
			this.ajax({
				url: "/facility/get",
				data: {},
				success: json => {
					//alert(json.facilities.length);
					this.hospitals = json.facilities;
					this.options = json.options;
				}
			});
		}
	},

	mounted() {
		this.refreshHospitals();
	},

	components: {
		"app-hospital-modal": FacilityModal
	}
};
</script>

<style lang="scss"></style>

// Dashboard.vue

<template>
	<div class="input-group-prepend jw-timepicker">
		<div class="jw-timepicker-selecters input-group">
			<select class="custom-select" v-model="time.h">
				<option v-for="n in 12" :key="n + '-select-time-hour'">{{ n }}</option>
			</select>
			<select class="custom-select" v-model="time.m">
				<option value="00">: 00</option>
				<option value="15">: 15</option>
				<option value="30">: 30</option>
				<option value="45">: 45</option>
			</select>
			<select class="custom-select" v-model="time.a">
				<option>AM</option>
				<option>PM</option>
			</select>
		</div>
	</div>
</template>

<script>
//import { mapGetters, mapActions } from 'vuex';

export default {
	props: ["time"],

	data() {
		return {
			msg: "Welcome to Your Vue.js App",
			hour: 0,
			minute: 0,
			meridian: "AM"
		};
	},

	methods: {
		// ...mapActions([
		//
		//   'ajax',
		//   //'setDestination'
		//
		// ]),
	},

	mounted() {
		//this.hour = this.time.split(':')[0];
		//this.minute = this.time.split(':')[1].split(' ')[0];
		//this.meridian = (this.time.split(':')[1].split(' ')[1]).toUpperCase();
	}
};
</script>

<style lang="scss"></style>
